<template>
    <section class="container h-screen flex items-center">
      <p-plug class="" />
    </section>
  </template>
  
<script setup>
import { loglib } from "@loglib/tracker"

onMounted(() => {
  loglib.record({
    id: "positive"
  })
})
</script>