<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup>
import {
  useRecentlyViewedStore,
  useCartStore,
  useOrderStore,
  useCategoryStore,
} from "~/stores";
import { getCategoriesTree } from "~/utils/helpers";

import { loglib } from "@loglib/tracker"


const recentlyViewedStore = useRecentlyViewedStore();
const categoriesStore = useCategoryStore();
const favoritesStore = useFavoritesStore();
const cartStore = useCartStore();

const orderStore = useOrderStore();

orderStore.$subscribe(() => {
  localStorage.setItem(
    LOCALSTORAGE_KEYS.order,
    JSON.stringify(orderStore.order)
  );
});

cartStore.$subscribe(() => {
  localStorage.setItem(LOCALSTORAGE_KEYS.cart, JSON.stringify(cartStore.cart));
});

recentlyViewedStore.$subscribe(() => {
  localStorage.setItem(
    LOCALSTORAGE_KEYS.viewedProduct,
    JSON.stringify(recentlyViewedStore.recentlyViewed)
  );
});

onMounted(() => {
  loglib.record({
    id: "positive"
  })

  let recentlyViewed =
    localStorage.getItem(LOCALSTORAGE_KEYS.viewedProduct) || "[]";
  recentlyViewedStore.setRecentlyViewed(JSON.parse(recentlyViewed));

  let favoriteProducts =
    localStorage.getItem(LOCALSTORAGE_KEYS.favoritesStore) || "[]";
  favoritesStore.setFavoritesStore(JSON.parse(favoriteProducts));

  let savedCart = localStorage.getItem(LOCALSTORAGE_KEYS.cart) || "{}";
  cartStore.setCart(JSON.parse(savedCart));


  {
    ;(function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          ;(m[i].a = m[i].a || []).push(arguments)
        }
      m[i].l = 1 * new Date()
      for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) {
          return
        }
      }
      ;(k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a)
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

    ym(97385419, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ecommerce: 'dataLayer'
    })
  }

});

const { $get } = useAxios();
const { data: category, pending: isCategoriesLoading } = await useAsyncData(
  "get all category",
  () => $get(`/api/v1/category`)
);

categoriesStore.setCategoryStore(getCategoriesTree(category));
</script>
